import { IS_DEVELOPMENT } from "../../utils/featureToggle";
import { Auth0ClientOptions } from "@auth0/auth0-spa-js";

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
if (!GAE_REGION) {
  throw new Error("REACT_APP_GAE_REGION is undefined");
}

export interface Auth0ApplicationConfig {
  audience: string;
  clientId: string;
  domain: string;
}

const DEVELOPMENT_CONFIGS: Auth0ApplicationConfig[] = [
  {
    audience: "tacklit-eu-apis",
    clientId: "l5LALJrWhsgHR7G7EISQYX4qjZnfUcmD",
    domain: "auth.tacklit.com"
  },
  {
    audience: "tacklit-eu-apis",
    clientId: "aDWh3Fp179Fplp7bUnkzkFAu9G6snGyO",
    domain: "procare.auth.tacklit.com"
  }
];

const PRODUCTION_CONFIGS: Record<string, Auth0ApplicationConfig[]> = {
  au: [
    {
      audience: "tacklit-au-apis",
      clientId: "7YWlPJpNZ2ADdAu6ZBAcoIFD8DUxmnD6",
      domain: "au.auth.tacklit.com"
    },
    {
      audience: "tacklit-au-apis",
      clientId: "aUojXLULUGnBCrTBXA1tiy4TX3hb8n3S",
      domain: "procare.auth.tacklit.com"
    }
  ],
  gb: [
    {
      audience: "tacklit-uk-apis",
      clientId: "OBVbNMhKHOgriuEcbIGyZZjg3Y4ODsWD",
      domain: "uk.auth.tacklit.com"
    }
  ],
  sa: [
    {
      audience: "tacklit-sa-apis",
      clientId: "9yNBuj7vHVFZc00GgQtZcBgLvXsV9q8Q",
      domain: "sa.auth.tacklit.com"
    }
  ]
};

const AUTH0_APPLICATION_CONFIGS = IS_DEVELOPMENT ? DEVELOPMENT_CONFIGS : PRODUCTION_CONFIGS[GAE_REGION];

if (!AUTH0_APPLICATION_CONFIGS?.length) {
  throw new Error(`No Auth0 application configuration for region ${IS_DEVELOPMENT ? "development" : GAE_REGION}`);
}

const generateAuth0ClientOptions = ({ audience, clientId, domain }: Auth0ApplicationConfig): Auth0ClientOptions => ({
  clientId,
  domain,
  authorizationParams: {
    audience,
    redirect_uri: window.location.origin
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true
});

export { AUTH0_APPLICATION_CONFIGS, generateAuth0ClientOptions };
