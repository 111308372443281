import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { RecordVoiceOverOutlined } from "@mui/icons-material";
import { EndRoomButton, LeaveRoomButton } from "../Buttons/EndCallButtons/EndCallButtons";
import { isMobile } from "../../utils";
import Menu from "./Menu/Menu";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import { useVideoContext } from "../VideoProvider";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
// import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import Hidden from "../Hidden/Hidden";
import { TelehealthRoomType } from "../../services/schedService";
import useIsRecording from "../../hooks/useIsRecording/useIsRecording";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: "fixed",
      display: "flex",
      padding: "0 1.43em",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0
      }
    },
    roomName: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 4
    },
    recordingIcon: {
      cursor: "pointer"
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: "104px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white"
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7"
        }
      }
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    gapLeft: {
      marginLeft: "2px"
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { telehealthRoomType, isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const { room, isCurrentUserInWaitingRoom } = useVideoContext();
  const participants = useParticipants();
  const isRecording = useIsRecording();

  return (
    <>
      {!isCurrentUserInWaitingRoom && isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              {!isCurrentUserInWaitingRoom ? (
                <Typography component="span" variant="body1">
                  <div className={classes.roomName}>
                    <span>Room {room!.name}</span>
                    {isRecording && (
                      <Tooltip title="This call is being recorded">
                        <RecordVoiceOverOutlined className={classes.recordingIcon} htmlColor="red" fontSize="medium" />
                      </Tooltip>
                    )}
                  </div>
                  <span>
                    {participants.length + 1} participant
                    {participants.length === 0 ? "" : "s"}
                  </span>
                </Typography>
              ) : (
                <Typography variant="body1">
                  You are currently in a waiting room
                  <br />
                  Room {room!.name}
                </Typography>
              )}
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              {telehealthRoomType === TelehealthRoomType.Video && <ToggleVideoButton disabled={isReconnecting} />}
              {!isCurrentUserInWaitingRoom && !isSharingScreen && !isMobile && (
                <ToggleScreenShareButton disabled={isReconnecting} />
              )}
              {/* {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" && <ToggleChatButton />} */}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <LeaveRoomButton />
                <EndRoomButton className={classes.gapLeft} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
