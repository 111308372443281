import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";

import { useVideoContext } from "../VideoProvider";
import { EndRoomButton, LeaveRoomButton } from "../Buttons/EndCallButtons/EndCallButtons";
import Menu from "../MenuBar/Menu/Menu";
import { useAppState } from "../../state";
import { RecordVoiceOverOutlined } from "@mui/icons-material";
import useIsRecording from "../../hooks/useIsRecording/useIsRecording";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: "white",
    display: "none",
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  roomName: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 2,
    fontSize: 14
  },
  endCallButton: {
    height: "28px",
    fontSize: "0.85rem",
    padding: "0 0.6em",
    marginRight: "2px"
  },
  settingsButton: {
    [theme.breakpoints.down("sm")]: {
      height: "28px",
      minWidth: "28px",
      border: "1px solid rgb(136, 140, 142)",
      padding: 0,
      marginRight: "2px"
    }
  }
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { user } = useAppState();
  const { room, participantsInWaitroom } = useVideoContext();
  const isRecording = useIsRecording();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.container}
      style={{
        padding: user?.isClinician && participantsInWaitroom.length > 0 ? 1 : "0 1em"
      }}
    >
      <Typography className={classes.roomName} component="span" variant="subtitle1">
        {isRecording && <RecordVoiceOverOutlined htmlColor="red" fontSize="small" />}
        {room!.name}
      </Typography>
      <div
        style={{
          marginRight: user?.isClinician && participantsInWaitroom.length > 0 ? 45 : 0
        }}
      >
        <LeaveRoomButton className={classes.endCallButton} />
        <EndRoomButton className={classes.endCallButton} />
        <Menu buttonClassName={classes.settingsButton} />
        <div />
      </div>
    </Grid>
  );
}
