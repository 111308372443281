import { Button, makeStyles } from "@material-ui/core";

import { useAppState } from "../../state";
import TacklitSecureLogo from "../IntroContainer/TacklitSecureLogo";
import UserMenu from "../IntroContainer/UserMenu/UserMenu";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import useAuthValidating from "../../hooks/useAuthValidating";

const useStyles = makeStyles((theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#F3FBFF",
    height: "100%"
  },
  mainLogoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  mainLogo: {
    height: 50
  },
  loginButtonContainer: {
    position: "absolute",
    zIndex: 20,
    top: 0,
    right: 0,
    margin: "1em",
    display: "flex",
    alignItems: "center"
  },
  loginButton: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0"
    }
  }
}));

interface BackgroundProps {
  children: React.ReactNode;
}

const Background = ({ children }: BackgroundProps) => {
  const classes = useStyles();
  const { user, signOut } = useAppState(false) || {};
  const navigate = useNavigate();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const { isRedirecting } = useAuthValidating();

  const login = () => {
    return navigate(`/clinicians${URLRoomName ? `/room/${URLRoomName}` : ""}`);
  };

  return (
    <>
      <div className={classes.background}>
        <TacklitSecureLogo
          containerProps={{
            className: classes.mainLogoContainer
          }}
          imgProps={{
            className: classes.mainLogo
          }}
        />
        {user && <UserMenu user={user} signOut={signOut} />}
        {!user && !isRedirecting && (
          <div className={classes.loginButtonContainer}>
            <Button variant="outlined" type="button" color="primary" className={classes.loginButton} onClick={login}>
              Login
            </Button>
          </div>
        )}
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Background;
