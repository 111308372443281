import AppStateProvider from "./state";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth0ProviderWithHistory from "./components/Auth0ProviderWithHistory/Auth0ProviderWithHistory";
import VideoRoutes from "./routes/VideoCallRoutes";
import PhoneCallRoutes from "./routes/PhoneCallRoutes";
import theme from "./theme";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import Logout from "./pages/Logout/Logout";
import { Auth0ApplicationConfigContextProvider } from "./contexts/Auth0ApplicationConfigContext/Auth0ApplicationConfigContext";
import Login from "./pages/Login/Login";

export const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <UnsupportedBrowserWarning>
        <BrowserRouter>
          <Auth0ApplicationConfigContextProvider>
            <Auth0ProviderWithHistory>
              <AppStateProvider>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />

                  {/* Protected Routes for voice calls */}
                  <Route path="/calls/*" element={<PhoneCallRoutes />} />

                  {/* Routes for video calls */}
                  <Route path="*" element={<VideoRoutes />} />
                </Routes>
              </AppStateProvider>
            </Auth0ProviderWithHistory>
          </Auth0ApplicationConfigContextProvider>
        </BrowserRouter>
      </UnsupportedBrowserWarning>
    </MuiThemeProvider>
  );
};

export default App;
